import type { AxiosResponse } from "axios";
import { deserialise } from "kitsu-core";

import qbiClient from "./qbi-client";

interface ResponseData<Type> {
  type: string;
  id: string;
  attributes: Type;
}

function handleResponse<Type>(response: AxiosResponse<ResponseData<Type>>): Type {
  const { data } = response;
  if (response.status === 204) {
    return data as Type;
  }
  const deserialized = deserialise(data) as { data: Type };
  return deserialized.data;
}

export async function get<Type>(endpoint: string): Promise<Type> {
  const response = await qbiClient.get<ResponseData<Type>>(endpoint);
  return handleResponse<Type>(response);
}

export async function patch<Type>(endpoint: string, requestData: unknown): Promise<Type> {
  const response = await qbiClient.patch<ResponseData<Type>>(endpoint, requestData);
  return handleResponse<Type>(response);
}

export async function post<Type>(endpoint: string, requestData: unknown): Promise<Type> {
  const response = await qbiClient.post<ResponseData<Type>>(endpoint, requestData);
  return handleResponse<Type>(response);
}

export async function remove(endpoint: string): Promise<void> {
  await qbiClient.delete<ResponseData<void>>(endpoint);
}
