import find from "lodash/find";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "features/auth";
import { type Organization } from "features/organization";

interface Params {
  organizationId: string;
}

export function useOrganizationId(): string {
  const { organizationId } = useParams<keyof Params>() as Params;
  return organizationId;
}

export function useOrganizationPath(pathEnd = ""): string {
  const organizationId = useOrganizationId();
  return `/w/${organizationId}/${pathEnd}`;
}

export function useOrganization(): Organization | undefined {
  const organizationId = useOrganizationId();
  const user = useUser();
  if (!user.data?.organizations.length) {
    return undefined;
  }
  const organization = find(
    user.data.organizations,
    (org) => org.organizationId === organizationId,
  );
  return organization;
}

export function useNavigateToOrganizationPage(): (to: string) => void {
  const organizationId = useOrganizationId();
  const navigate = useNavigate();
  function navigateToOrganizationPage(to: string): void {
    navigate(`/w/${organizationId}/${to}`);
  }
  return navigateToOrganizationPage;
}
