import type { ReactElement } from "react";
import { Link } from "react-router-dom";

import Head from "components/head";
import { SupportLink } from "components/mailto-link";

interface Properties {
  header?: string;
}

export default function NotFoundMessage({ header }: Properties): ReactElement {
  return (
    <>
      <Head title="Page not found - QuickBI" />
      <div className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
            {header ?? "Page not found"}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/" className="p-button">
              Go back home
            </Link>
            <SupportLink>Contact QuickBI support</SupportLink>
          </div>
        </div>
      </div>
    </>
  );
}
