import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import type { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateEvent } from "features/events";
import { useCreateOrganization } from "features/organization";
import toast from "lib/react-hot-toast";

const validationSchema = z.object({
  name: z
    .string()
    .min(4, { message: "Must be at least 4 characters long" })
    .max(30, { message: "Can't be more than 30 characters long" })
    .regex(/^[a-zA-Z].*$/, {
      message: "Must start with a letter",
    })
    .regex(/^[a-zA-Z0-9' \-!]+$/, {
      message: "Can only contain letters a-z, numbers or spaces",
    }),
});

export type CreateOrganizationData = z.infer<typeof validationSchema>;

const defaultValues = {
  name: "",
};

interface CreateOrganizationFormProps {
  onSuccess: (organizationId: string) => void;
}

export function CreateOrganizationForm({ onSuccess }: CreateOrganizationFormProps): ReactElement {
  const createOrganization = useCreateOrganization();
  const createEvent = useCreateEvent();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateOrganizationData>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  function onSubmit(data: CreateOrganizationData): void {
    createOrganization.mutate(data, {
      onSuccess: (responseData) => {
        toast.success("New workspace was created!");
        onSuccess(responseData.id);
      },
      onError: (error) => {
        console.log("error", error);
        // TODO: Proper error handling
        toast.error("Something went wrong.");
      },
    });
  }

  function getFormErrorMessage(name: keyof CreateOrganizationData): ReactElement | undefined {
    if (!errors[name]) {
      return undefined;
    }
    return <small className="p-error block">{errors[name]?.message}</small>;
  }

  const fieldName = "name";

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="field mb-6">
        <label htmlFor={fieldName} className={clsx({ "p-error": errors.name })}>
          Workspace name*
        </label>
        <Controller
          name={fieldName}
          control={control}
          render={({ field, fieldState }): ReactElement => (
            <InputText
              id={field.name}
              {...field}
              autoFocus
              className={clsx("my-2 placeholder-gray-300", { "p-invalid": fieldState.invalid })}
              placeholder="My Company"
            />
          )}
        />
        {getFormErrorMessage(fieldName)}
        <small>
          It's recommended to use your company name here. You cannot change this name later.
        </small>
      </div>
      <Button
        type="submit"
        label={createOrganization.isPending ? "Creating..." : "Create"}
        className="p-button mt-2"
        disabled={createOrganization.isPending}
        onClick={() => createEvent.mutate({ name: "signup_create_workspace_button_clicked" })}
      />
    </form>
  );
}
