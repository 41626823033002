import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import clsx from "clsx";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import type { ReactElement } from "react";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { z } from "zod";

import { useLogin } from "features/auth";
import toast from "lib/react-hot-toast";

const validationSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, "Password is required"),
});

export type EmailLoginData = z.infer<typeof validationSchema>;

const defaultValues = {
  email: "",
  password: "",
};

function setFormErrors(error: AxiosError, setError: UseFormSetError<EmailLoginData>) {
  // @ts-expect-error - We have not properly defined the error response data for every case
  const errors = error.response?.data?.errors;
  if (!errors) {
    toast.error("Something went wrong. Please try again shortly or contact QuickBI support.");
  }

  for (const error of errors) {
    const sourcePointer = error.source?.pointer;
    if (sourcePointer?.includes("email")) {
      setError("email", { type: "server", message: error.detail }, { shouldFocus: true });
    } else if (sourcePointer?.includes("password")) {
      setError("password", { type: "server", message: error.detail }, { shouldFocus: true });
    } else {
      setError("root", { type: "server", message: error.detail });
    }
  }
}

export function EmailLoginForm(): ReactElement {
  const login = useLogin();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<EmailLoginData>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  function onSubmit(credentials: EmailLoginData): void {
    login.mutate(
      { loginType: "email", credentials },
      {
        onError: (error) => {
          setFormErrors(error as AxiosError, setError);
        },
      },
    );
  }

  function getFormErrorMessage(name: keyof EmailLoginData | "root"): ReactElement | undefined {
    if (!errors[name]) {
      return undefined;
    }
    if (name === "root") {
      return <Message className="mb-2" severity="error" content={errors.root?.message} />;
    }
    return <small className="p-error block">{errors[name]?.message}</small>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
      <div className="field mb-4">
        <label htmlFor="email" className={clsx({ "p-error": errors.email })}>
          Email
        </label>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }): ReactElement => (
            <InputText
              id={field.name}
              {...field}
              className={clsx("my-2 placeholder-gray-300", { "p-invalid": fieldState.invalid })}
            />
          )}
        />
        {getFormErrorMessage("email")}
      </div>
      <div className="field mb-4">
        <label htmlFor="password" className={clsx({ "p-error": errors.password })}>
          Password
        </label>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }): ReactElement => (
            <Password
              id={field.name}
              {...field}
              feedback={false}
              toggleMask
              className={clsx("my-2 placeholder-gray-300", { "p-invalid": fieldState.invalid })}
            />
          )}
        />
        {getFormErrorMessage("password")}
      </div>
      {getFormErrorMessage("root")}

      <Button
        type="submit"
        severity="secondary"
        size="small"
        label={login.isPending ? "Logging in..." : "Log in"}
        className="p-button mt-2"
        disabled={login.isPending}
      />
    </form>
  );
}
