import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { post } from "api";
import { useOrganizationId } from "features/organization";

type EventData = Record<string, string | boolean | undefined>;

// ------------------------------------
// API request
// ------------------------------------

export interface RequestAttributes {
  data?: EventData;
  name: string;
  organization_id: string;
}

async function createEvent(attributes: RequestAttributes): Promise<void> {
  const requestData = {
    data: {
      type: "events",
      attributes,
    },
  };
  return post(`events`, requestData);
}

// ------------------------------------
// react-query mutation
// ------------------------------------

interface MutationValues {
  data?: EventData;
  name: string;
}

export type UseCreateEventResult = UseMutationResult<void, AxiosError, MutationValues>;

export function useCreateEvent(): UseCreateEventResult {
  const organizationId = useOrganizationId();
  return useMutation({
    mutationFn: async (values: MutationValues) =>
      createEvent({
        ...values,
        organization_id: organizationId,
      }),
  });
}
