import type { ReactElement, ReactNode } from "react";

interface Properties {
  children: ReactNode;
}

export function SalesLink({ children }: Properties): ReactElement {
  return (
    <a
      className="underline"
      href="mailto:sales@quickbi.io"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

export function SupportLink({ children }: Properties): ReactElement {
  return (
    <a
      className="underline"
      href="mailto:support@quickbi.io"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
