import type { ReactElement } from "react";
import { Toaster as OriginalToaster } from "react-hot-toast";

export { default } from "react-hot-toast";

const TOAST_POSITION_TOP = 80;
const TOAST_POSITION_RIGHT = 30;
const TOAST_SUCCESS_DURATION = 5000;
const TOAST_ERROR_DURATION = 8000;
const TOAST_CUSTOM_DURATION = 10000;

export function Toaster(): ReactElement {
  return (
    <OriginalToaster
      position="top-right"
      containerStyle={{
        top: TOAST_POSITION_TOP,
        right: TOAST_POSITION_RIGHT,
      }}
      toastOptions={{
        success: {
          duration: TOAST_SUCCESS_DURATION,
        },
        error: {
          duration: TOAST_ERROR_DURATION,
        },
        custom: {
          duration: TOAST_CUSTOM_DURATION,
        },
      }}
    />
  );
}
