import * as Sentry from "@sentry/react";
import {
  type QueryClient,
  type QueryKey,
  useMutation,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import get from "lodash/get";
import { configureAuth } from "react-query-auth";

import { client } from "api";
import { type User } from "features/auth";
import toast from "lib/react-hot-toast";

const userQueryKey = ["authenticated-user"];

interface SessionData {
  data:
    | {
        type: "sessions";
        id: string;
        attributes: User;
      }
    | [];
}

// ------------------------------------
// Helpers
// ------------------------------------

function configureCSRFToken(user?: User | null): void {
  if (user) {
    client.defaults.headers.common["X-CSRF-TOKEN"] = user.csrfToken;
  } else {
    delete client.defaults.headers.common["X-CSRF-TOKEN"];
  }
}

// Axios error handling docs: https://axios-http.com/docs/handling_errors
function handleAxiosErrors(error: AxiosError, showToast = false) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status !== 401) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      console.log(error.config);
      Sentry.captureException(error);
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("No response error", error.request);
    console.log(error.config);
    Sentry.captureException(error);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error in setting up the request", error.message);
    console.log(error.config);
    Sentry.captureException(error);
  }
  if (showToast) {
    toast.error("Something went wrong. Please try again shortly or contact QuickBI support.");
  }
  // Rethrow the error so that react-query error handling keeps working normally
  throw error;
}

function handleSessionResponse(responseData: SessionData): User | null {
  const user = get(responseData, "data.attributes", null);
  configureCSRFToken(user);
  return user;
}

// ------------------------------------
// API functions
// ------------------------------------

async function getSessions(): Promise<User | null> {
  const response = await client.get("sessions").catch(handleAxiosErrors);
  return handleSessionResponse(response?.data as SessionData);
}

// Login functions

type LoginData =
  | { loginType: "email"; credentials: EmailLoginCredentials }
  | { loginType: "google"; credentials: GoogleLoginCredentials };

export interface EmailLoginCredentials {
  email: string;
  password: string;
}

export interface GoogleLoginCredentials {
  token: string;
}

async function login({ loginType, credentials }: LoginData): Promise<User> {
  switch (loginType) {
    case "email": {
      return await loginWithEmail(credentials);
    }
    case "google": {
      return await loginWithGoogle(credentials);
    }
    default: {
      throw new Error(`Invalid loginType: ${loginType}!`);
    }
  }
}

async function loginWithDemoCode(code: string): Promise<User> {
  const requestData = {
    data: {
      type: "demo_logins",
      attributes: {
        code,
      },
    },
  };
  const response = await client
    .post("sessions/demo-login", requestData)
    .catch((error) => handleAxiosErrors(error, true));
  return handleSessionResponse(response?.data as SessionData)!;
}

async function loginWithEmail(credentials: EmailLoginCredentials): Promise<User> {
  const requestData = {
    data: {
      type: "email_logins",
      attributes: {
        email: credentials.email,
        password: credentials.password,
      },
    },
  };
  const response = await client.post("sessions/email-login", requestData).catch(handleAxiosErrors);
  return handleSessionResponse(response?.data as SessionData)!;
}

async function loginWithGoogle(credentials: GoogleLoginCredentials): Promise<User> {
  const requestData = {
    data: {
      type: "google_logins",
      attributes: {
        token: credentials.token,
      },
    },
  };
  const response = await client.post("sessions/google-login", requestData).catch(handleAxiosErrors);
  return handleSessionResponse(response?.data as SessionData)!;
}

async function logOut(userId: string): Promise<string> {
  const response = await client.delete(`sessions/${userId}`);
  configureCSRFToken();
  return response.data as Promise<string>;
}

async function signupWithEmail(credentials: EmailLoginCredentials): Promise<User> {
  const requestData = {
    data: {
      type: "email_signups",
      attributes: {
        email: credentials.email,
        password: credentials.password,
      },
    },
  };
  const response = await client.post("email-signup", requestData).catch(handleAxiosErrors);
  return handleSessionResponse(response?.data as SessionData)!;
}

async function signupWithGoogle(credentials: GoogleLoginCredentials): Promise<User> {
  const requestData = {
    data: {
      type: "google_signups",
      attributes: {
        token: credentials.token,
      },
    },
  };
  const response = await client.post("google-signup", requestData).catch(handleAxiosErrors);
  return handleSessionResponse(response?.data as SessionData)!;
}

// ------------------------------------
// Queries
// ------------------------------------

// Queries provided by react-query-auth
export const { useUser, useLogin, useRegister, useLogout, AuthLoader } = configureAuth({
  userFn: getSessions,
  loginFn: login,
  registerFn: signupWithGoogle,
  // @ts-expect-error - react-query-auth logoutFn type is not quite compatible with our logout function
  logoutFn: logOut,
  userKey: userQueryKey,
});

interface DemoLoginAttrs {
  code: string;
}

export function useLoginWithDemoCode(): UseMutationResult<User, AxiosError, DemoLoginAttrs> {
  return useMutation({
    mutationFn: async (values: DemoLoginAttrs) => loginWithDemoCode(values.code),
  });
}

export function useSignupWithEmail(): UseMutationResult<User, AxiosError, EmailLoginCredentials> {
  return useMutation({
    mutationFn: async (credentials: EmailLoginCredentials) => signupWithEmail(credentials),
  });
}

export const userLoader = (queryClient: QueryClient) => async (): Promise<User | null> => {
  const query = {
    queryKey: userQueryKey,
    queryFn: async (): Promise<User | null> => getSessions(),
  };
  return (
    queryClient.getQueryData(query.queryKey as QueryKey) ?? (await queryClient.fetchQuery(query))
  );
};

export function useIsLoggedIn(): boolean {
  const user = useUser();
  return !!user.data;
}

export function useIsSuperuser(): boolean {
  const user = useUser();
  return !!user.data?.isSuperuser;
}
