import type { ReactElement } from "react";
import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom";

import Head from "components/head";
import { SupportLink } from "components/mailto-link";
import NotFoundMessage from "components/not-found-message";

export function ErrorPage(): ReactElement {
  const error = useRouteError();

  if (error && isRouteErrorResponse(error) && error.status === 404) {
    return <NotFoundMessage />;
  }

  return (
    <>
      <Head title="Page not found - QuickBI" />
      <div className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-2xl font-bold tracking-tight">Something went wrong</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            QuickBI developers have been notified about the issue and will try to resolve it as soon
            as possible.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/" className="p-button">
              Go back home
            </Link>
            <SupportLink>Contact QuickBI support</SupportLink>
          </div>
        </div>
      </div>
    </>
  );
}
