import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { isAxiosError } from "axios";
import { PrimeReactProvider } from "primereact/api";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { getRoutes } from "routes";

import "./index.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primeicons/primeicons.css";

// ------------------------------------
// Initialize Sentry
// ------------------------------------

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://4bb0423ddca44a7da67b71d990b09f56@o118246.ingest.sentry.io/4505323270635520",
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // URLs for which distributed tracing should be enabled
    tracePropagationTargets: [
      "http://api.localhost:8080/",
      "https://api2-staging.quickbi.io/",
      "https://api2.quickbi.io/",
    ],
    tracesSampleRate: 1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// ------------------------------------
// React Query configuration
// ------------------------------------

function reloadPageOn401(error: unknown): boolean {
  const status = isAxiosError(error) && error.response?.status;
  // Reload page and redirect to login page for 401 errors
  if (status && status === 401) {
    const url = error.request.responseURL;
    // Don't reload page if 401 occurs during login
    if (!url.includes("/google-login") && !url.includes("/email-login")) {
      window.location.reload();
    }
    return true;
  }
  return false;
}

const MAX_RETRIES = 1;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.POSITIVE_INFINITY,
      retry: MAX_RETRIES,
      networkMode: import.meta.env.PROD ? "online" : "always",
    },
    mutations: {
      networkMode: import.meta.env.PROD ? "online" : "always",
    },
  },
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      if (reloadPageOn401(error)) {
        return;
      }
      Sentry.withScope((scope) => {
        scope.setContext("mutation", {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[],
          );
        }
        Sentry.captureException(error);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (reloadPageOn401(error)) {
        return;
      }
      Sentry.withScope((scope) => {
        scope.setContext("query", { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")]);
        Sentry.captureException(error);
      });
    },
  }),
});

// ------------------------------------
// Router configuration
// ------------------------------------

// Wrap createBrowserRouter with Sentry for the instrumentation to work.
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const routes = getRoutes({ queryClient });
const router = sentryCreateBrowserRouter(routes);

// Handle failing dynamic imports (https://vitejs.dev/guide/build#load-error-handling)
// This most likely happens because of new deployments so reload the page for all assets to update.
window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault();
  window.location.reload();
});

// ------------------------------------
// Root render
// ------------------------------------

const container = document.querySelector("#root");
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID as string}>
        <QueryClientProvider client={queryClient}>
          <PrimeReactProvider>
            <HubspotProvider>
              <RouterProvider router={router} />
              <ReactQueryDevtools initialIsOpen={false} />
            </HubspotProvider>
          </PrimeReactProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </StrictMode>,
  );
}
