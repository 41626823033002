import { Outlet } from "react-router-dom";

import { Toaster } from "lib/react-hot-toast";

export function Component(): React.ReactElement {
  return (
    <>
      <Toaster />
      <Outlet />
    </>
  );
}

Component.displayName = "RootLayout";
