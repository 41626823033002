import { useMutation, type UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { post } from "api";
import { organizationQueryKeys } from "features/superuser";

// ------------------------------------
// API request
// ------------------------------------

interface RequestParams {
  name: string;
}

interface ResponseData {
  createdAt: string;
  id: string;
  name: string;
  type: "organizations";
}

async function createOrganization(attributes: RequestParams): Promise<ResponseData> {
  const requestData = {
    data: {
      type: "organizations",
      attributes,
    },
  };
  return post<ResponseData>("organizations", requestData);
}

// ------------------------------------
// react-query mutation
// ------------------------------------

export default function useCreateOrganization(): UseMutationResult<
  ResponseData,
  Error,
  RequestParams
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values: RequestParams) => createOrganization(values),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.list() });
    },
  });
}
